@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.body{
  font-family: 'Montserrat', sans-serif !important;
}

.main-text{
  font-family: 'Montserrat', sans-serif !important;
}

/* General */
.no-dec{
  text-decoration: none;
}

.no-dec:hover{
  text-decoration: none;
}

.font-800{
  font-weight: 900;
}

.card-image{
  height: 14rem;
}

.card-image-tour{
  height: 16rem;
  filter: brightness(80%)
}

/* .max-height{
  height: max-content;
} */


.hero-background{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./Images//Heros/Hero9.jpg)no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-explore{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./Images//Heros/Hero7.jpg)no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover; 
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-ella{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./Images/Heros/Hero3.jpg)no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-galle{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://live.staticflickr.com/7459/13992500770_ab4b4e1847_c.jpg")no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-polonnaruwa{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://live.staticflickr.com/65535/52280232947_19f0168290_k.jpg")no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-sigiriya{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://live.staticflickr.com/7369/10796185073_aed3f6b065_c.jpg")no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-minneriya{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://live.staticflickr.com/7876/46576793502_f5a57fe561_h.jpg")no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-trincop{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://live.staticflickr.com/65535/52174521170_1076a77cf9_k.jpg")no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-sunset-yacht{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://images.unsplash.com/photo-1599582350162-83106f579198?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-yala{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./Images/Heros/Hero4.jpg)no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-ridiyagama{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./Images/Heros/Hero10.jpg)no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-kandy{
  height: 70vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(34, 33, 33, 0.5)), url(./Images/Heros/Hero5.jpg)no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important;
}

.hero-background-kumana{
  height: 70vh;
  background: url(https://images.unsplash.com/photo-1627401099591-4772d63b86a4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1201&q=80) no-repeat center center  ; 
 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero-background-perahera{
  height: 70vh;
   background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://live.staticflickr.com/65535/50183728751_9466c5aef0_k.jpg) no-repeat center center  ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero-background-tendays{
  height: 70vh;
   background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./Images/Heros/Hero8.jpg) no-repeat center center  ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero-background-about{
  height: 70vh;
   background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://images.unsplash.com/photo-1552055569-d54ae89a11b7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2048&q=80) no-repeat center center  ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero-background-trinco{
  height: 70vh;
  background: url(https://images.unsplash.com/photo-1491497895121-1334fc14d8c9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) no-repeat center center fixed ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero-background-excursions{
  height: 70vh;
  background: url(./Images/Heros/Hero6.jpg) no-repeat center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.background-about {
  height: 100%;
  min-height: 30vh;
   background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://images.unsplash.com/photo-1552055569-d54ae89a11b7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2048&q=80) no-repeat center center  ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 5px;
  
}

.icon-image{
  height: 70px;
  
  
}

.card-image-guide{
   height: 18rem;
}

.icon-text{
  font-weight: 700 !important;
  font-family: 'Montserrat', sans-serif;
}

.yellow-span{
  height: 30px;
  width: 10px;
  background-color: #f9c20d;
}

.big-text{
color: white;
  font-weight: 700 !important;
  font-size: 35px !important;
  font-family: 'Montserrat', sans-serif;
}

.mid-text{
color: white;
  font-weight: 700 !important;
  /* font-size: 35px !important; */
  font-family: 'Montserrat', sans-serif;
}

.deco-text{
  font-family: 'Montserrat', sans-serif;
}

.green-text{
  color: darkseagreen;
}

.blue-text{
  color: #303030;
}

.grey-text{
  color: gray;
}

.border-thin{
  border: solid 2px #303030;
  min-width: 100%;
  border-radius: 3px;
  font-family: 'Montserrat', sans-serif;
}

.card-width{
  width: 100%;
}

.client-nikita{
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background: url(./Images/Reviews/i14.JPG) no-repeat center center  ; 
}
.client-ivan{
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background: url(./Images/Reviews/i4.JPG) no-repeat center center ; 
  
}
.client-vladmir{
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background: url(./Images/Reviews/i1.jpg) no-repeat center center ; 
}
.client-anna{
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background: url(./Images/Reviews/i25.jpeg) no-repeat center center ; 
}

.data-box{
  position: relative;
  top: -35px;
  margin: 0px auto; 
  height: 40px;
  width: 100%;
  border-radius: 5px;
  background: #303030;
}

.min-height-review{
max-height: 70vh;
}

.makebutton{
  cursor: pointer;
}

.hero-test{
  height: 150px; 
  border: solid black;
}

.kandy-background{
  height: 150px;
  /* background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://images.thrillophilia.com/image/upload/s--CflGwxAE--/c_fill,g_auto,h_600,q_auto,w_975/f_auto,fl_strip_profile/v1/images/photos/000/110/166/original/1491646844_Sri_Lanka_-_029_-_Kandy_Temple_of_the_Tooth.jpg.jpg)no-repeat  center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif !important; */
}

.carousal-height{
  height: 150px;
  object-fit: cover;
}

.team-box{
  height: 400px;
 border-radius: 5px;
background-color: #E8E8E8;

}

.team-image-box{
  height: 100px;
  width: 100px;
  border-radius: 50px;
}

.yellow-text{
  color: #f9c20d;
}

.tenday-gallary8-img{
   width: 100%; 
    height: 215px; 
  object-fit: cover 
    
}

.gallary8{
  overflow:hidden
}

.cardrivermain{
  width: 90%; 
    /* height: 400px;  */
  object-fit: cover 
}

.yellow-box{
  background-color: #f9c20d;
  border-radius: 5px;
}

/* Car Rental */

.car-type{
min-height: 150px;
height:100%
}

.cardriverbox{
  width: 90%; 
    /* height: 400px;  */
  object-fit: cover 
}